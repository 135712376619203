import React from "react";
import "./App.css";
import Razorpay from "./components/Razorpay";
import Header from "./components/Header";

function App() {
  return (
    <div className="app-container">
    <Header />
    <Razorpay />
    </div>
  );
}

export default App;
