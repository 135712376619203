import { BASE_URL } from "./apiConstant";
import { doPost } from "./apiWrapper";

export const postDonation = async (payload) => {
  const url = `${BASE_URL}/donation`;
  return await doPost(url, payload);
};

export const postVerifyPayment = async (payload) => {
  const url = `${BASE_URL}/donation/success`;
  return await doPost(url, payload);
};
