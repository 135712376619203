import React, { useRef } from "react";
import logo from "../assests/img/logo.png";
import { createDonation, verifyPayment } from "../api/apiAction";

function Razorpay() {
  const userInfoRef = useRef();
  const errorRefs = {
    contact: useRef(""),
    fullName: useRef(""),
    email: useRef(""),
    amount: useRef(""),
    panNo: useRef(""),
    address: useRef(""),
    donationPurpose: useRef(""),
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(orderDetails) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const order = {
      order: {
        amount: orderDetails.amount,
        currency: "INR",
        receipt: `${orderDetails.fullName} Receipt`,
        notes: {
          note: orderDetails.donationPurpose,
        },
      },
      user: {
        fullName: orderDetails.fullName,
        contact: orderDetails.contact,
        email: orderDetails.email,
        pan: orderDetails.panNo,
        address: orderDetails.address,
      },
    };

    const result = await createDonation(order);

    const { amount, id: order_id, currency, rzp_order_key: key } = result.data;
    if (!amount || !order_id || !currency || !key) {
      alert("Payment failed, try again!");
      return;
    }
    const options = {
      key: key, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Guru Ravidas Sabha",
      contact: orderDetails.contact,
      description: "Guru Ravidas Sabha Test Transaction",
      image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          paymentId: response.razorpay_payment_id,
          orderId: response.razorpay_order_id,
          signature: response.razorpay_signature,
        };

        const verifyPaymentData = await verifyPayment(data);
        console.log("verifyPaymentData", verifyPaymentData);

        if (verifyPaymentData.data.success) {
          alert("Payment Successfull!");
        } else {
          alert("Payment not Successfull!");
        }
      },
      prefill: {
        name: orderDetails.fullName,
        email: orderDetails.email,
        contact: orderDetails.contact,
      },
      notes: {
        address: orderDetails.address,
      },
      theme: {
        color: "#f39849",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  function validatePAN(pan) {
    // Regular expression to match PAN format
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(pan);
  }
  const handleFormSubmit = (e) => {
    // displayRazorpay()
    e.preventDefault();
    const userInfoDetails = {};
    const contact = String(userInfoRef.current.contact.value);
    if (!contact || contact.length !== 10) {
      errorRefs.contact.current.innerHTML =
        "Please enter 10 digit mobile number";
      return;
    }
    userInfoDetails.contact = contact;

    const fullName = userInfoRef.current.fullName.value;
    if (!fullName) {
      errorRefs.fullName.current.innerHTML = "This field is required.";
      return;
    }
    const nameRegex = /^[A-Za-z\s'-]{2,50}$/; // Letters, spaces, hyphens, apostrophes, 2-50 characters
    if (!nameRegex.test(fullName)) {
      errorRefs.fullName.current.innerHTML =
        "Invalid full name. Only letters, spaces, hyphens, or apostrophes are allowed (2-50 characters).";
      return;
    }
    userInfoDetails.fullName = fullName;

    const email = userInfoRef.current.email.value;
    if (!email) {
      errorRefs.email.current.innerHTML = "Please enter a valid email address.";
      return;
    }
    userInfoDetails.email = email;

    const amount = userInfoRef.current.amount.value;
    const regex = /^([1-9][0-9]{0,4}|100000)$/;
    if (regex.test(amount)) {
      userInfoRef.current.amount.value = amount;
    } else {
      errorRefs.amount.current.innerHTML =
        "Please enter a value greater than or equal to 1. Max amount allowed is 100000";
      return;
    }
    userInfoDetails.amount = amount;

    const panNo = userInfoRef.current.panNo.value;
    if (!validatePAN(panNo)) {
      errorRefs.panNo.current.innerHTML = "Please enter valid pan no";
      return;
    }
    userInfoDetails.panNo = panNo;

    const address = userInfoRef.current.address.value;
    if (!address) {
      errorRefs.address.current.innerHTML = "This field is required.";
      return;
    }
    userInfoDetails.address = address;

    const donationPurpose = userInfoRef.current.donationPurpose.value;
    if (!donationPurpose) {
      errorRefs.donationPurpose.current.innerHTML = "This field is required.";
      return;
    }
    userInfoDetails.donationPurpose = donationPurpose;
    displayRazorpay(userInfoDetails);
  };

  const Input = ({ label, name, type }) => {
    let options = {};
    if (name === "contact") {
      options.maxLength = 10;
      options.minLength = 10;
      options.placeholder = "Please enter contact number";
      options.onChange = function () {
        errorRefs[name].current.innerHTML = "";
        userInfoRef.current[name].value = userInfoRef.current[
          name
        ].value.replace(/[^0-9]/g, "");
      };
    }
    if (name === "fullName") {
      options.placeholder = "Please enter full name";
      options.onChange = function () {
        errorRefs[name].current.innerHTML = "";
      };
    }
    if (name === "email") {
      options.placeholder = "Please enter email";
      options.onChange = function () {
        errorRefs[name].current.innerHTML = "";
      };
    }
    if (name === "amount") {
      options.placeholder = "Please enter amount";
      options.onChange = function () {
        const amount = userInfoRef.current[name].value.replace(/[^0-9]/g, "");
        userInfoRef.current[name].value = amount;
        options.onChange = function () {
          errorRefs[name].current.innerHTML = "";
        };
      };
    }
    if (name === "panNo") {
      options.maxLength = 10;
      options.placeholder = "Please enter PAN";
      options.onChange = function () {
        errorRefs[name].current.innerHTML = "";
        userInfoRef.current[name].value =
          userInfoRef.current[name].value.toUpperCase();
      };
    }
    if (name === "address") {
      options.placeholder = "Please enter address";
      options.onChange = function () {
        errorRefs[name].current.innerHTML = "";
      };
    }
    if (name === "donationPurpose") {
      options.placeholder = "Please enter Donation Purpose";
      options.onChange = function () {
        errorRefs[name].current.innerHTML = "";
      };
    }
    return (
      <div className="user-input-field-width">
        <div className="user-input-field user-input-field-mr-3">
          <label htmlFor={name}> {label}</label>
          <input type={type} name={name} {...options} required />
        </div>
        <div className="user-input-field">
          <label htmlFor={name + 1}> {""}</label>
          <div ref={errorRefs[name]} className="required-field"></div>
        </div>
      </div>
    );
  };
  return (
    <div className="donatetion-container">
      <div className="user-info-container">
        <div className="user-info">User Information</div>
        <form
          ref={userInfoRef}
          className="user-info-details"
          onSubmit={handleFormSubmit}
        >
          <Input label="Contact Numner" name="contact" type="text" />
          <Input label="Full Name" name="fullName" type="text" />
          <Input label="Email ID" name="email" type="email" />
          <Input label="Amount" name="amount" type="text" />
          <Input label="PAN No" name="panNo" type="text" />
          <Input label="Address" name="address" type="text" />
          <Input label="Donation Purpose" name="donationPurpose" type="text" />
          <button type="submit" className="nav-cta pay-button">
            Pay
          </button>
        </form>
      </div>
    </div>
  );
}

export default Razorpay;
