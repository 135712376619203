import React from "react";
import logoIMG from "../assests/img/logo.png";
function Header() {
  const websiteName = "Guru Ravidas Sabha";
  return (
    <div className="card-header-align-text nav-logo">
      <img alt="logo" src={logoIMG} className="logo-image" /> &nbsp;{" "}
      {websiteName}
    </div>
  );
}

export default Header;
